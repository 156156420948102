import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FaEye,
  FaRobot, // Icona per robot
  FaDatabase, // Icona per gemello digitale
  FaPlug, // Icona per connessione hardware
} from "react-icons/fa"; // Import delle icone

const Sidebar = () => {
  const [isMonitoringOpen, setIsMonitoringOpen] = useState(false);

  return (
    <aside className="sidebar">
      <ul>
        {/* Production Line Monitoring */}
        <li>
          <div
            className="sidebar-link"
            style={{
              cursor: "pointer",
              marginLeft: "8px",
              fontSize: "0.9rem",
            }}
            onClick={() => setIsMonitoringOpen(!isMonitoringOpen)}
          >
            <FaEye className="icon" /> Production line
          </div>
          {isMonitoringOpen && (
            <ul className="submenu">
              <li>
                <Link to="/robotic_arm_1" className="sidebar-link">
                  <FaRobot className="icon" /> Robotic Arm 1
                </Link>
              </li>
              <li>
                <Link to="/robotic_arm_2" className="sidebar-link">
                  <FaRobot className="icon" /> Robotic Arm 2
                </Link>
              </li>
              <li>
                <Link to="/machine_3" className="sidebar-link">
                  <FaRobot className="icon" /> Machine 3
                </Link>
              </li>
              <li>
                <Link to="/robotic_arm_4" className="sidebar-link">
                  <FaRobot className="icon" /> Robotic Arm 4
                </Link>
              </li>
              <li>
                <Link to="/machine_5" className="sidebar-link">
                  <FaRobot className="icon" /> Machine 5
                </Link>
              </li>
              <li>
                <Link to="/machine_6" className="sidebar-link">
                  <FaRobot className="icon" /> Machine 6
                </Link>
              </li>
            </ul>
          )}
        </li>

        {/* Gemello Digitale */}
        <li>
          <Link to="/gemello-digitale" className="sidebar-link">
            <FaDatabase className="icon" /> Gemello digitale
          </Link>
        </li>

        {/* Hardware Connection */}
        <li>
          <Link to="/hardware-connection" className="sidebar-link">
            <FaPlug className="icon" /> Hardware connection
          </Link>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;

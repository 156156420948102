import * as THREE from "three";
import { MeshStandardMaterial } from "three"; // Importa il materiale

export const grayMaterialflangia = new THREE.MeshStandardMaterial({
  color: 0x4b4b4b,
});

export const blackMaterial = new THREE.MeshStandardMaterial({
  color: 0x333333,
  metalness: 0.5,
  roughness: 0.7,
  opacity: 0.8,
});

export const yellowMaterial = new THREE.MeshStandardMaterial({
  color: 0xffc000,
  metalness: 0.2,
  roughness: 0.5,
  transparent: false,
});

export const greyMaterial = new THREE.MeshStandardMaterial({
  color: 0x808080,
  metalness: 0.3,
  roughness: 0.6,
  opacity: 0.9,
});

export const silverMaterial = new THREE.MeshStandardMaterial({
  color: 0x4b4b4b,
}); // Grigio scuro

export const darkGrayMaterial = new THREE.MeshStandardMaterial({
  color: 0x878787,
  roughness: 0.5,
}); // Grigio chiaro alluminio opaco

export const glassMaterial = new MeshStandardMaterial({
  color: "white", // Colore base (può essere bianco o trasparente)
  opacity: 0.3, // Imposta la trasparenza
  transparent: true, // Abilita la trasparenza
  refractionRatio: 0.98, // Aggiunge rifrazione (per l'effetto vetro)
  metalness: 0.1, // Poco metallico
  roughness: 0.05, // Superficie liscia per l'effetto vetro
  reflectivity: 0.5, // Riflettività, per un effetto più realistico
});

import React from "react";
import { useGLTF } from "@react-three/drei";
import * as THREE from "three";

export default function Conveyor(props) {
  const { nodes } = useGLTF("/3D/conveyor.gltf");

  const transparentGrayMaterial = new THREE.MeshStandardMaterial({
    color: 0xa5a5a5, // Grigio scuro
    opacity: 0.8, // Livello di trasparenza (0 = completamente trasparente, 1 = opaco)
    transparent: true, // Necessario per abilitare la trasparenza
    roughness: 0.7, // Per dare un po' di effetto opaco
    metalness: 0.3, // Per aggiungere un effetto metallico
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Conveyor.geometry}
        material={transparentGrayMaterial} // Applica il materiale
        rotation={[Math.PI, -Math.PI / 2, 0]}
      />
    </group>
  );
}

useGLTF.preload("/3D/conveyor.gltf");

import React, { useRef, useState, useEffect } from "react";
import { useGLTF } from "@react-three/drei";
import * as THREE from "three";
import useMqttClient from "../hooks/useMqttClient";

import {
  grayMaterialflangia,
  blackMaterial,
  yellowMaterial,
  greyMaterial,
} from "../colors/colors";

export default function RoboticArm3(props) {
  const { nodes } = useGLTF("/3D/fanuc_simplified1_withEE_motor3.gltf");

  const [targetRotations, setTargetRotations] = useState({
    base: 0,
    lowerArm: 0,
    upperArm: 0,
    forwardArm: 0,
    head: 0,
    endEffector: 0,
  });

  const currentRotations = useRef({});

  const baseRef = useRef(nodes.base);
  const headRef = useRef(nodes.head);
  const upperArmRef = useRef(nodes["upper-arm"]);
  const lowerArmRef = useRef(nodes["lower-arm"]);
  const forwardArmRef = useRef(nodes["forward-arm"]);
  const endEffectorRef = useRef(nodes["end-effector_1"]);

  const { message } = useMqttClient("robotic_arm_3/telemetry"); // Subscribe to MQTT topic

  useEffect(() => {
    if (message) {
      // Extract the rotations from the message (assuming it's an array of angles in degrees)
      const { base, lowerArm, upperArm, forwardArm, head, endEffector } =
        message.data;

      console.log(
        `Received new target rotations: ${base}, ${lowerArm}, ${upperArm}, ${forwardArm}, ${head}, ${endEffector}`
      );

      // Convert degrees to radians
      const [
        baseRad,
        lowerArmRad,
        upperArmRad,
        forwardArmRad,
        headRad,
        endEffectorRad,
      ] = [base, lowerArm, upperArm, forwardArm, head, endEffector].map(
        (deg) => deg * (Math.PI / 180)
      );

      // Update target rotations state
      setTargetRotations({
        base: baseRad,
        lowerArm: lowerArmRad,
        upperArm: upperArmRad,
        forwardArm: forwardArmRad,
        head: headRad,
        endEffector: endEffectorRad,
      });
    }
  }, [message]);

  useEffect(() => {
    if (baseRef.current) {
      const axis = new THREE.Vector3(1, 0, 0); // Asse X locale
      const angle = targetRotations.base - (currentRotations.current.base || 0);
      baseRef.current.rotateOnAxis(axis, angle);
      currentRotations.current.base = targetRotations.base;
    }
    if (headRef.current) {
      const axis = new THREE.Vector3(0, 0, 1); // Asse Z locale
      const angle = targetRotations.head - (currentRotations.current.head || 0);
      headRef.current.rotateOnAxis(axis, angle);
      currentRotations.current.head = targetRotations.head;
    }
    if (upperArmRef.current) {
      const axis = new THREE.Vector3(0, 0, 1); // Asse Z locale
      const angle =
        targetRotations.upperArm -
        (currentRotations.current.upperArm || 0) -
        (currentRotations.current.lowerArm || 0);
      upperArmRef.current.rotateOnAxis(axis, angle);
      currentRotations.current.upperArm =
        targetRotations.upperArm - (currentRotations.current.lowerArm || 0);
    }
    if (lowerArmRef.current) {
      const axis = new THREE.Vector3(0, 0, 1); // Asse Z locale
      const angle =
        targetRotations.lowerArm - (currentRotations.current.lowerArm || 0);
      lowerArmRef.current.rotateOnAxis(axis, angle);
      currentRotations.current.lowerArm = targetRotations.lowerArm;
    }
    if (forwardArmRef.current) {
      const axis = new THREE.Vector3(0, 1, 0); // Asse Y locale
      const angle =
        targetRotations.forwardArm - (currentRotations.current.forwardArm || 0);
      forwardArmRef.current.rotateOnAxis(axis, angle);
      currentRotations.current.forwardArm = targetRotations.forwardArm;
    }
    if (endEffectorRef.current) {
      const axis = new THREE.Vector3(0, 1, 0); // Asse Y locale
      const angle =
        targetRotations.endEffector -
        (currentRotations.current.endEffector || 0);
      endEffectorRef.current.rotateOnAxis(axis, angle);
      currentRotations.current.endEffector = targetRotations.endEffector;
    }
  }, [targetRotations]);

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Banchetti.geometry}
        material={greyMaterial}
        position={[0.01, 0, -0.064]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Banchetto_Fanuc.geometry}
        material={greyMaterial}
        position={[0.365, -0.186, -0.064]}
      />
      <group position={[0.4, 0.1, -0.07]} rotation={[-1.571, 1.571, 0]}>
        <skinnedMesh
          geometry={nodes["end-effector"].geometry}
          material={nodes["end-effector"].material}
          skeleton={nodes["end-effector"].skeleton}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.fanuc001.geometry}
          material={greyMaterial}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          position={[0.1, -0.07, -0.5]}
        />
        <skinnedMesh
          geometry={nodes.fanuc002.geometry}
          material={yellowMaterial}
          skeleton={nodes.fanuc002.skeleton}
        />
        <skinnedMesh
          geometry={nodes.fanuc003.geometry}
          material={yellowMaterial}
          skeleton={nodes.fanuc003.skeleton}
        />
        <skinnedMesh
          geometry={nodes.fanuc004.geometry}
          material={yellowMaterial}
          skeleton={nodes.fanuc004.skeleton}
        />
        <skinnedMesh
          geometry={nodes.fanuc005.geometry}
          material={greyMaterial}
          skeleton={nodes.fanuc005.skeleton}
        />
        <skinnedMesh
          geometry={nodes.fanuc006.geometry}
          material={blackMaterial}
          skeleton={nodes.fanuc006.skeleton}
        />
        <skinnedMesh
          geometry={nodes.fanuc007.geometry}
          material={greyMaterial}
          skeleton={nodes.fanuc007.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Flangiainf.geometry}
          material={grayMaterialflangia}
          skeleton={nodes.Flangiainf.skeleton}
        />
        <skinnedMesh
          geometry={nodes.pinza1_1.geometry}
          material={blackMaterial}
          skeleton={nodes.pinza1_1.skeleton}
        />
        <skinnedMesh
          geometry={nodes.pinza2_1.geometry}
          material={blackMaterial}
          skeleton={nodes.pinza2_1.skeleton}
        />
        <skinnedMesh
          geometry={nodes.pinza3_1.geometry}
          material={blackMaterial}
          skeleton={nodes.pinza3_1.skeleton}
        />
        <primitive object={nodes.root} />
        <primitive object={nodes.neutral_bone} />
        <primitive object={nodes.neutral_bone_1} />
      </group>
    </group>
  );
}

useGLTF.preload("/3D/fanuc_simplified1_withEE_motor3.gltf");

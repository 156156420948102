import React, { useState, useEffect } from "react";
import { useGLTF } from "@react-three/drei";
import {
  silverMaterial,
  darkGrayMaterial,
  blackMaterial,
} from "../colors/colors";
import useMqttClient from "../hooks/useMqttClient";

export default function Stazione4(props) {
  const { nodes } = useGLTF("/3D/stazione4.gltf");

  const [avvitatoreSuperioreZ, setAvvitatoreSuperioreZ] = useState(1); // posizione iniziale z
  const [avvitatoreInferioreZ, setAvvitatoreInferioreZ] = useState(15); // posizione iniziale z

  const { message } = useMqttClient("stazione4/telemetry");

  useEffect(() => {
    if (message) {
      setAvvitatoreSuperioreZ(message.avvitatoreSuperioreZ);
      setAvvitatoreInferioreZ(message.avvitatoreInferioreZ);
    }
  }, [message]);

  return (
    <group {...props} dispose={null} scale={[0.016, 0.016, 0.016]}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Pressa.geometry}
        material={silverMaterial}
        position={[-6, 0, -138]}
        rotation={[0, 0, 0]}
      />
      {/* <mesh
        castShadow
        receiveShadow
        geometry={nodes.Pallet.geometry}
        material={blackMaterial}
        position={[-4.119, -1.77, -138.2]}
      /> */}
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.MeshStatica.geometry}
        material={blackMaterial}
        position={[-4.119, -1.77, -138.2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.AvvitatoreSuperiore.geometry}
        material={darkGrayMaterial}
        position={[-4.119, avvitatoreSuperioreZ, -138.2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.AvvitatoreInferiore.geometry}
        material={darkGrayMaterial}
        position={[-4.119, avvitatoreInferioreZ, -138.2]}
      />
    </group>
  );
}

useGLTF.preload("/3D/stazione4.gltf");

import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Navbar from "../../../components/Navbar";
import Sidebar from "../../../components/Sidebar";
import useMqttClient from "../../../hooks/useMqttClient";

export default function RoboticArmConsumptionChart() {
  const [consumptionData, setConsumptionData] = useState([]);
  const [anomalyReport, setAnomalyReport] = useState(null); // For anomaly report
  const { message, publish } = useMqttClient("robotic_arm_1/consumption");

  useEffect(() => {
    if (message) {
      console.log("Received message:", message);
      // Add the new message to the consumption data
      setConsumptionData((prevData) => {
        // Keep only the latest 100 records
        const updatedData = [
          ...prevData,
          {
            timestamp: message.timestamp,
            base: message.data.base,
            lowerArm: message.data.lowerArm,
            upperArm: message.data.upperArm,
            forwardArm: message.data.forwardArm,
            head: message.data.head,
            endEffector: message.data.endEffector,
            reportAnomalie: message.report_anomalie || null, // Add the anomaly report if present
          },
        ];

        return updatedData.slice(-100);
      });

      // Update anomaly report if exists in the message
      if (message.report_anomalie) {
        setAnomalyReport(message.report_anomalie);
      }
    }
  }, [message]);

  return (
    <div className="section-page">
      <Navbar />
      <Sidebar />
      <div className="main-content-splitted-2">
        <div className="widget-full-width">
          <h1 style={{ color: "black" }}>
            Joint Consumption Robotic Arm 1 Real-Time
          </h1>
          <div className="w-full">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={consumptionData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="timestamp"
                  interval="preserveStartEnd"
                  angle={-45}
                  textAnchor="end"
                  height={60}
                />
                <YAxis
                  domain={[0, 1]}
                  label={{
                    value: "Consumo",
                    angle: -90,
                    position: "insideLeft",
                  }}
                />
                <Tooltip
                  labelClassName="text-black"
                  contentStyle={{ backgroundColor: "rgba(255,255,255,0.8)" }}
                />
                <Legend verticalAlign="top" height={36} iconType="circle" />
                {/* Lines for consumption */}
                <Line
                  type="monotone"
                  dataKey="base"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
                <Line
                  type="monotone"
                  dataKey="lowerArm"
                  stroke="#82ca9d"
                  activeDot={{ r: 8 }}
                />
                <Line
                  type="monotone"
                  dataKey="upperArm"
                  stroke="#ffc658"
                  activeDot={{ r: 8 }}
                />
                <Line
                  type="monotone"
                  dataKey="forwardArm"
                  stroke="#ff7300"
                  activeDot={{ r: 8 }}
                />
                <Line
                  type="monotone"
                  dataKey="head"
                  stroke="#413ea0"
                  activeDot={{ r: 8 }}
                />
                <Line
                  type="monotone"
                  dataKey="endEffector"
                  stroke="#e70202"
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        {/* Anomaly Report Widget */}
        {anomalyReport && (
          <div className="widget-anomalie">
            <h1 style={{ color: "black" }}>Anomaly Report</h1>
            <div className="anomaly-details">
              <p>
                <strong>Type:</strong> {anomalyReport.tipo}
              </p>
              <p>
                <strong>Timestamp:</strong> {anomalyReport.timestamp}
              </p>
              <p>
                <strong>Severity:</strong> {anomalyReport.severita}
              </p>
              <h3>Anomalous Features:</h3>
              <ul>
                {anomalyReport.feature_anomale.map((feature, index) => (
                  <li key={index}>
                    <p>
                      <strong>Feature:</strong> {feature.feature}
                    </p>
                    <p>
                      <strong>Current Value:</strong> {feature.valore_corrente}
                    </p>
                    <p>
                      <strong>Z-Score:</strong> {feature.z_score}
                    </p>
                    <p>
                      <strong>Historical Mean:</strong> {feature.media_storica}
                    </p>
                    <p>
                      <strong>Historical Std Dev:</strong> {feature.std_storica}
                    </p>
                  </li>
                ))}
              </ul>
              <p>
                <strong>Recommendation:</strong> {anomalyReport.raccomandazione}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

import { blackMaterial, glassMaterial } from "../colors/colors";
import React from "react";
import { useGLTF } from "@react-three/drei";

export default function Stazione5(props) {
  const { nodes } = useGLTF("/3D/stazione5.gltf");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Macchinario.geometry}
        material={blackMaterial}
        position={[-0.947, 0.735, -0.082]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Vetro.geometry}
        material={glassMaterial}
        position={[-0.947, 0.735, -0.082]}
        rotation={[Math.PI / 2, 0, 0]}
      />
    </group>
  );
}

useGLTF.preload("/3D/stazione5.gltf");

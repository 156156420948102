import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import useMqttClient from "../hooks/useMqttClient";
import {
  Check,
  XCircle,
  Clock,
  Cpu,
  Thermometer,
  Server,
  Database,
} from "lucide-react";

export default function HardwareConnection() {
  const message = useMqttClient("system/connection");

  // Log the message only when it's available
  useEffect(() => {
    if (message) {
      console.log("Received message:", message);
    }
  }, [message]);

  // Helper function to determine status color
  const getStatusColor = (status) => {
    if (status === "active") {
      return "bg-green-500"; // Colore verde per stato attivo
    }
    if (status === "inactive") {
      return "bg-red-500"; // Colore rosso per stato inattivo
    }
    return "bg-yellow-500"; // Colore giallo per altri stati (ad esempio avviso)
  };

  // Helper function to format boolean values
  const formatBoolean = (value) => {
    return value ? (
      <span className="text-emerald-600 flex items-center gap-1 font-medium">
        <Check size={16} className="stroke-2" /> Yes
      </span>
    ) : (
      <span className="text-red-600 flex items-center gap-1 font-medium">
        <XCircle size={16} className="stroke-2" /> No
      </span>
    );
  };

  // Access the individual data inside message
  const hardware = message?.message?.hardware || {};
  const local_broker = message?.message?.local_broker || {};
  const cloud_broker = message?.message?.cloud_broker || {};

  return (
    <div className="section-page">
      <Navbar />
      <Sidebar />
      <div className="main-content">
        <div className="widget">
          <div className="panel-container">
            <div className="grid-container">
              {/* Hardware Status Panel */}
              <div className="panel md:col-span-2">
                <div className="panel-header bg-orange-200">
                  <Cpu className="icon" />
                  <h2 className="font-bold text-lg">Hardware Status</h2>
                </div>
                <div className="panel-body">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div className="status-indicator col-span-1 md:col-span-3">
                      <div
                        className={`status-dot ${getStatusColor(
                          hardware?.status
                        )}`}
                      ></div>
                      <span className="status-text">Status:</span>
                      <span className="ml-2 capitalize">
                        {hardware?.status}
                      </span>
                    </div>

                    <div className="data-section">
                      <span className="label">PLC Connected</span>
                      {formatBoolean(hardware?.plc_connection)}
                    </div>
                    <div className="data-section">
                      <span className="label">Uptime</span>
                      <div className="flex items-center">
                        <Clock size={16} className="mr-1 text-black-400" />
                        <span className="text-black-400">
                          {hardware?.uptime}
                        </span>
                      </div>
                    </div>

                    <div className="data-section">
                      <span className="label">Last Message</span>
                      <span className="value">
                        {hardware?.last_message_time}
                      </span>
                    </div>

                    <div className="data-section">
                      <span className="label">CPU Load</span>
                      <div className="cpu-memory-bar">
                        <div
                          className={`fill ${
                            hardware?.cpu_load > 80
                              ? "bg-red-500"
                              : hardware?.cpu_load > 60
                              ? "bg-yellow-500"
                              : "bg-green-500"
                          }`}
                          style={{ width: `${hardware?.cpu_load}%` }}
                        ></div>
                      </div>
                      <span className="text-sm mt-1">
                        {hardware?.cpu_load}%
                      </span>
                    </div>

                    <div className="data-section">
                      <span className="label">Memory Usage</span>
                      <div className="cpu-memory-bar">
                        <div
                          className={`fill ${
                            hardware?.memory_usage > 80
                              ? "bg-red-500"
                              : hardware?.memory_usage > 60
                              ? "bg-yellow-500"
                              : "bg-green-500"
                          }`}
                          style={{
                            width: `${hardware?.memory_usage}%`,
                          }}
                        ></div>
                      </div>
                      <span className="text-sm mt-1">
                        {hardware?.memory_usage}%
                      </span>
                    </div>

                    <div className="data-section">
                      <span className="label">Temperature</span>
                      <div className="flex items-center">
                        <Thermometer
                          size={16}
                          className="mr-1 text-black-400"
                        />
                        <span
                          className={`${
                            hardware?.temperature > 60
                              ? "text-red-500"
                              : hardware?.temperature > 45
                              ? "text-yellow-500"
                              : "text-green-600"
                          }`}
                        >
                          {hardware?.temperature}°C
                        </span>
                      </div>
                    </div>

                    {/* Wi-Fi Information */}
                    <div className="data-section">
                      <span className="label">Wi-Fi SSID</span>
                      <span className="value">{hardware?.wifi?.ssid}</span>
                    </div>
                    <div className="data-section">
                      <span className="label">Wi-Fi IP</span>
                      <span className="value">{hardware?.wifi?.ip}</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Cloud Broker Status Panel */}
              <div className="panel">
                <div className="panel-header bg-purple-600">
                  <Database className="icon" />
                  <h2 className="font-bold text-lg">Cloud Broker</h2>
                </div>
                <div className="panel-body">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="status-indicator col-span-2">
                      <div
                        className={`status-dot ${getStatusColor(
                          cloud_broker?.status
                        )}`}
                      ></div>
                      <span className="status-text">Status:</span>
                      <span className="ml-2 capitalize">
                        {cloud_broker?.status}
                      </span>
                    </div>
                    <div className="data-section">
                      <span className="label">Receiving Data</span>
                      {formatBoolean(cloud_broker?.receiving_data)}
                    </div>
                  </div>
                </div>
              </div>

              {/* Web Gateway Broker Status Panel */}
              <div className="panel">
                <div className="panel-header bg-blue-600">
                  <Server className="icon" />
                  <h2 className="font-bold text-lg">Web Gateway Broker</h2>
                </div>
                <div className="panel-body">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="status-indicator col-span-2">
                      <div
                        className={`status-dot ${getStatusColor(
                          local_broker?.status
                        )}`}
                      ></div>
                      <span className="status-text">Status:</span>
                      <span className="ml-2 capitalize">
                        {local_broker?.status}
                      </span>
                    </div>
                    <div className="data-section">
                      <span className="label">Connected Clients</span>
                      <span className="value">
                        {local_broker?.connected_clients}
                      </span>
                    </div>
                    <div className="data-section">
                      <span className="label">Uptime</span>
                      <span className="value">{local_broker?.uptime}</span>
                    </div>
                    <div className="data-section">
                      <span className="label">Sending Data</span>
                      {formatBoolean(local_broker?.sending_data)}
                    </div>
                    <div className="data-section">
                      <span className="label">Publish Messages</span>
                      <span className="value">
                        {local_broker?.publish_received}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

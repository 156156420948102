import React from "react";
import * as THREE from "three"; // Importa THREE per creare materiali
import { useGLTF } from "@react-three/drei";

export function Motor({ stationIndex, ...props }) {
  const { nodes } = useGLTF("/3D/motore.gltf");

  // Definizione dei materiali con colori specifici
  const grayMaterial = new THREE.MeshStandardMaterial({ color: 0x4b4b4b }); // Grigio scuro metallico
  const lightGrayMaterial = new THREE.MeshStandardMaterial({ color: 0xb0c4de }); // Grigio chiaro argenteo
  const silverMaterial = new THREE.MeshStandardMaterial({ color: 0xc0c0c0 }); // Argento
  const darkGrayMaterial = new THREE.MeshStandardMaterial({ color: 0x2f4f4f }); // Grigio molto scuro
  const blackMaterial = new THREE.MeshStandardMaterial({ color: 0x000000 }); // Nero

  // Mappa delle mesh da mostrare in base all'indice della stazione
  const stationMeshesMap = {
    2: ["Flangiainf"], // Stazione 1
    1: ["Flangiainf", "Statore"], // Stazione 2
    0: ["Flangiainf", "Statore", "Rotore"], // Stazione 3
    5: ["Flangiainf", "Statore", "Rotore", "Flangiasup", "Ventola"], // Stazione 5
    4: [], //Stazione 6 [operatore]
    3: [], // Stazione Pre 1
    6: ["Flangiainf", "Statore", "Rotore", "Flangiasup"], // Stazione 4
  };

  const meshesToShow = stationMeshesMap[stationIndex] || [];

  return (
    <group {...props} dispose={null}>
      {meshesToShow.includes("Flangiainf") && (
        <mesh
          geometry={nodes.Flangiainf.geometry}
          material={grayMaterial} // Assegna il materiale grigio scuro
        />
      )}
      {meshesToShow.includes("Statore") && (
        <mesh
          geometry={nodes.Statore.geometry}
          material={lightGrayMaterial} // Assegna il materiale grigio chiaro
        />
      )}
      {meshesToShow.includes("Rotore") && (
        <mesh
          geometry={nodes.Rotore.geometry}
          material={silverMaterial} // Assegna il materiale argento
        />
      )}
      {meshesToShow.includes("Flangiasup") && (
        <mesh
          geometry={nodes.Flangiasup.geometry}
          material={darkGrayMaterial} // Assegna il materiale grigio molto scuro
        />
      )}
      {meshesToShow.includes("Ventola") && (
        <mesh
          geometry={nodes.Ventola.geometry}
          material={blackMaterial} // Assegna il materiale nero
        />
      )}
    </group>
  );
}

useGLTF.preload("/3D/motore.gltf");

// Pallets.jsx
import React, { useRef, useMemo, useState, useEffect } from "react";
import { useFrame } from "@react-three/fiber";
import { Pallet } from "./pallet";
import { Line } from "@react-three/drei";
import * as THREE from "three";
import useMqttClient from "../hooks/useMqttClient";
/**
 * Crea il percorso della cinghia trasportatrice utilizzando una CatmullRomCurve3.
 * @returns {THREE.CatmullRomCurve3} Il percorso della cinghia trasportatrice.
 */
function createConveyorBeltPath() {
  const points = [];
  const conveyorX = 0; // X del Conveyor
  const conveyorY = 0.53; // Y del Conveyor
  const conveyorZ = 0; // Z del Conveyor

  const lineLength = 8; // Lunghezza delle linee rette
  const lineSpacing = 0.515; // Distanza tra le linee parallele
  const radius = lineSpacing / 2; // Raggio delle semicirconferenze alle estremità

  const halfLength = lineLength / 2; // Metà della lunghezza per centrare il percorso

  // Linea retta 1: da sinistra a destra
  for (let x = -halfLength; x <= halfLength; x += 0.1) {
    points.push(
      new THREE.Vector3(x + conveyorX, conveyorY, -radius + conveyorZ)
    );
  }

  // Semicerchio 1: da destra in basso a destra in alto
  const center1 = new THREE.Vector3(
    halfLength + conveyorX,
    conveyorY,
    conveyorZ
  );
  for (
    let theta = Math.PI * 1.5;
    theta <= Math.PI * 2.5;
    theta += Math.PI / 20
  ) {
    const x = center1.x + radius * Math.cos(theta);
    const z = center1.z + radius * Math.sin(theta);
    points.push(new THREE.Vector3(x, conveyorY, z));
  }

  // Linea retta 2: da destra a sinistra
  for (let x = halfLength; x >= -halfLength; x -= 0.1) {
    points.push(
      new THREE.Vector3(x + conveyorX, conveyorY, radius + conveyorZ)
    );
  }

  // Semicerchio 2: da sinistra in alto a sinistra in basso
  const center2 = new THREE.Vector3(
    -halfLength + conveyorX,
    conveyorY,
    conveyorZ
  );
  for (
    let theta = Math.PI * 0.5;
    theta <= Math.PI * 1.5;
    theta += Math.PI / 20
  ) {
    const x = center2.x + radius * Math.cos(theta);
    const z = center2.z + radius * Math.sin(theta);
    points.push(new THREE.Vector3(x, conveyorY, z));
  }

  // Ruota i punti di 90° attorno all'asse Y
  const angle = THREE.MathUtils.degToRad(270);
  const rotationMatrix = new THREE.Matrix4().makeRotationY(angle);
  points.forEach((point) => point.applyMatrix4(rotationMatrix));

  // Crea il percorso chiuso con CatmullRomCurve3
  const path = new THREE.CatmullRomCurve3(points, true);
  return path;
}

export default function Pallets() {
  const path = useMemo(() => createConveyorBeltPath(), []);

  const stations = useMemo(
    () => [
      {
        id: 0,
        t: 0.05,
        title: "Stazione 3",
        description: "machine_3",
      },
      {
        id: 1,
        t: 0.206,
        title: "Stazione 2",
        description: "robotic_arm_2",
      },
      {
        id: 2,
        t: 0.352,
        title: "Stazione 1",
        description: "robotic_arm_1",
      },
      {
        id: 3,
        t: 0.55,
        title: "Stazione Operatore",
        description: "Operatore",
      },
      {
        id: 4,
        t: 0.6572,
        title: "Stazione 6",
        description: "machine_6",
      },
      {
        id: 5,
        t: 0.817,
        title: "Stazione 4-5",
        description: "robotic_arm_4 e machine_5",
      },
    ],
    []
  );

  // State for palletSwitch
  const [palletSwitch, setPalletSwitch] = useState(false);

  const { message } = useMqttClient("conveyor/movement"); // Subscribe to MQTT topic

  useEffect(() => {
    if (message) {
      // Extract the palletSwitch value from the message
      const { palletSwitch } = message.data;
      console.log(`Received new palletSwitch value: ${palletSwitch}`);
      setPalletSwitch(palletSwitch);
    }
  }, [message]);

  // State for pallets
  const [pallets, setPallets] = useState(
    Array.from({ length: stations.length }, (_, i) => ({
      id: i,
      currentT: stations[i].t,
      stationIndex: i,
      moving: false,
      stoppedTime: 0,
    }))
  );

  const palletRefs = useRef([]);

  // Refs to track previous palletSwitch value and pending movements
  const prevPalletSwitchRef = useRef(false);
  const pendingMovementsRef = useRef(0);

  // useEffect to detect changes in palletSwitch and increment pending movements
  useEffect(() => {
    if (palletSwitch && !prevPalletSwitchRef.current) {
      // palletSwitch changed from false to true
      pendingMovementsRef.current += 1;
    }
    prevPalletSwitchRef.current = palletSwitch;
  }, [palletSwitch]);

  // useFrame to update pallet movement
  useFrame((_, delta) => {
    let speed;
    speed = 0.03;

    const newPallets = pallets.map((pallet, index) => {
      let updatedPallet = { ...pallet };

      if (updatedPallet.moving) {
        // Logic to move pallets towards the next station
        const currentT = updatedPallet.currentT;
        const stationIndex = updatedPallet.stationIndex;
        const nextStationIndex =
          (stationIndex - 1 + stations.length) % stations.length;
        const targetT = stations[nextStationIndex].t;

        const tIncrement = speed * delta;
        let deltaT = (currentT - targetT + 1) % 1;

        if (deltaT <= tIncrement) {
          // Arrived at the station
          updatedPallet.currentT = targetT;
          updatedPallet.moving = false;
          updatedPallet.stoppedTime = 0;
          updatedPallet.stationIndex = nextStationIndex;
        } else {
          // Move towards the next station
          updatedPallet.currentT = (currentT - tIncrement + 1) % 1;
        }
      } else {
        // Pallet stopped at station
        updatedPallet.stoppedTime += delta;
      }

      // Update position and rotation of the pallet
      const position = path.getPointAt(updatedPallet.currentT);
      const tangent = path.getTangentAt(updatedPallet.currentT);

      if (palletRefs.current[index]) {
        palletRefs.current[index].position.copy(position);
        const angle = Math.atan2(tangent.x, tangent.z);
        palletRefs.current[index].rotation.set(0, angle, 0);
      }

      return updatedPallet;
    });

    setPallets(newPallets);

    // After updating pallets, check if we need to start moving
    if (pendingMovementsRef.current > 0) {
      const palletsAreStopped = newPallets.every((pallet) => !pallet.moving);

      if (palletsAreStopped) {
        // Start moving pallets
        const palletsWithMoving = newPallets.map((pallet) => ({
          ...pallet,
          moving: true,
          stoppedTime: 0,
        }));
        setPallets(palletsWithMoving);
        // Decrement pending movements
        pendingMovementsRef.current -= 1;
      }
    }
  });

  return (
    <>
      {/* Display the path for reference */}
      <Line points={path.getPoints(200)} color="grey" lineWidth={0.01} />
      {/* Render the pallets */}
      {pallets.map((pallet, index) => (
        <Pallet
          key={pallet.id}
          ref={(ref) => (palletRefs.current[index] = ref)}
          currentT={pallet.currentT}
          stationIndex={pallet.stationIndex}
        />
      ))}
    </>
  );
}

import React, { forwardRef } from "react";
import { useGLTF } from "@react-three/drei";
import * as THREE from "three";
import { Motor } from "./motor";

export const Pallet = forwardRef(
  ({ currentT, stationIndex, ...props }, ref) => {
    const { nodes } = useGLTF("/3D/Pallet.gltf");

    const darkGrayMaterial = new THREE.MeshStandardMaterial({
      color: "#333333",
      roughness: 1,
      metalness: 0,
    });

    return (
      <group ref={ref} {...props} dispose={null}>
        {/* Pallet Mesh */}
        <mesh
          geometry={nodes.Pallet.geometry}
          material={darkGrayMaterial}
          rotation={[Math.PI / 2, 0, 0]}
        />
        {/* Motor positioned on top of the pallet */}
        <Motor position={[0, 0.32, 0]} stationIndex={stationIndex} />
      </group>
    );
  }
);

useGLTF.preload("/3D/Pallet.gltf");

// src/App.js
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/DashboardPage";
import HardwareConnection from "./pages/HardwareConnection";
import PrivateRoute from "./components/PrivateRoute";
import AccessDeniedPage from "./pages/AccessDeniedPage";
import RoboticArm1 from "./pages/ProductionLine/robotic_arm_1/RoboticArm1";
import JointConsumption_RoboticArm1 from "./pages/ProductionLine/robotic_arm_1/JointConsumption_RoboticArm1";
import RoboticArm2 from "./pages/ProductionLine/robotic_arm_2/RoboticArm2";
import Machine3 from "./pages/ProductionLine/machine_3/Machine3";
import RoboticArm4 from "./pages/ProductionLine/robotic_arm_4/RoboticArm4";
import Machine5 from "./pages/ProductionLine/machine_5/Machine5";
import Machine6 from "./pages/ProductionLine/machine_6/Machine6";
import GemelloDigitalePage from "./pages/GemelloDigitalePage";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute requiredRoles={["Admin"]}>
                <DashboardPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/gemello-digitale"
            element={
              <PrivateRoute requiredRoles={[]}>
                <GemelloDigitalePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/robotic_arm_1"
            element={
              <PrivateRoute requiredRoles={["Admin"]}>
                <RoboticArm1 />
              </PrivateRoute>
            }
          />
          <Route
            path="/robotic_arm_1/joint_consumption"
            element={
              <PrivateRoute requiredRoles={["Admin"]}>
                <JointConsumption_RoboticArm1 />
              </PrivateRoute>
            }
          />
          <Route
            path="/robotic_arm_2"
            element={
              <PrivateRoute requiredRoles={["Admin"]}>
                <RoboticArm2 />
              </PrivateRoute>
            }
          />
          <Route
            path="/machine_3"
            element={
              <PrivateRoute requiredRoles={["Admin"]}>
                <Machine3 />
              </PrivateRoute>
            }
          />
          <Route
            path="/robotic_arm_4"
            element={
              <PrivateRoute requiredRoles={["Admin"]}>
                <RoboticArm4 />
              </PrivateRoute>
            }
          />
          <Route
            path="/machine_5"
            element={
              <PrivateRoute requiredRoles={["Admin"]}>
                <Machine5 />
              </PrivateRoute>
            }
          />
          <Route
            path="/machine_6"
            element={
              <PrivateRoute requiredRoles={["Admin"]}>
                <Machine6 />
              </PrivateRoute>
            }
          />
          <Route
            path="/hardware-connection"
            element={
              <PrivateRoute requiredRoles={["Admin"]}>
                <HardwareConnection />
              </PrivateRoute>
            }
          />

          <Route path="/access-denied" element={<AccessDeniedPage />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;

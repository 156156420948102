import React from "react";
import { Canvas } from "@react-three/fiber";
import Lighting from "./Lighting";
import Floor from "../../GLTFJSX/Floor";
import { Stage, OrbitControls } from "@react-three/drei"; // Import Text from drei
import RoboticArm1 from "../../GLTFJSX/Fanuc_1";
import RoboticArm2 from "../../GLTFJSX/Fanuc_2";
import RoboticArm3 from "../../GLTFJSX/Fanuc_3";
import Stazione3 from "../../GLTFJSX/stazione3";
import Stazione4 from "../../GLTFJSX/stazione4";
import Stazione5 from "../../GLTFJSX/stazione5";
import Conveyor from "../../GLTFJSX/Conveyor";
import Pallets from "../../GLTFJSX/Pallets";

const Main = () => {
  return (
    <div>
      <div className="widget">
        <Canvas
          className="fullscreen-canvas"
          shadows
          camera={{ position: [0, 5, -10], fov: 60 }}
        >
          <color attach="background" />
          <Lighting />
          <Floor />
          <Stage
            intensity={1.2}
            environment="warehouse"
            shadows={{ type: "soft" }}
            adjustCamera={0.8}
          />

          {/* Posizionamento e rotazione dei componenti in base ai dati del CSV */}
          <RoboticArm1
            position={[1.7, 0.07, 2.15]}
            rotation={[0, Math.PI, 0]}
          />
          <RoboticArm2 position={[-1.7, 0.07, -1.6]} rotation={[0, 0, 0]} />
          <RoboticArm3
            position={[1.7, 0.07, -0.4]}
            rotation={[0, Math.PI, 0]}
          />
          <Stazione3
            position={[2, 0.19, -2.45]}
            rotation={[0, Math.PI / 2, 0]}
          />
          <Stazione4 position={[-0.23, -0.5, -1.6]} rotation={[0, 0, 0]} />
          <Stazione5 position={[0, -0.6, 1.3]} rotation={[0, 0, 0]} />
          <Conveyor position={[0, 0.27, 0]} rotation={[0, 0, 0]} />
          <Pallets />
          {/* <Fences position={[0, 0, 0]} /> */}

          <OrbitControls
            makeDefault
            minPolarAngle={0}
            maxPolarAngle={Math.PI / 2}
            target={[0, 0, 0]} // Set the target to (0, 0, 0) to help orient the scene
            zoomSpeed={1.3} // Adjust the zoom speed
            enableDamping={true} // Aggiungi il damping per un movimento più fluido
            dampingFactor={0.2} // Imposta il fattore di smorzamento per il movimento
            rotateSpeed={0.5} // Imposta la velocità di rotazione della telecamera
          />
        </Canvas>
      </div>
    </div>
  );
};

export default Main;

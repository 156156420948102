import { useState, useEffect } from "react";
import mqtt from "mqtt";

const useMqttClient = (topic) => {
  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState("🔄 Connessione in corso...");
  const [brokerUrl, setBrokerUrl] = useState("wss://app.geminiautomation.it/mqtt/"); // Imposta un valore predefinito

  const [client, setClient] = useState(null); // Aggiungi client MQTT come stato

  // Funzione per pubblicare messaggi
  const publish = (topic, message) => {
    if (client && client.connected) {
      client.publish(topic, JSON.stringify(message), (err) => {
        if (err) {
          console.error("❌ Errore nella pubblicazione:", err);
        } else {
          console.log(`📤 Messaggio inviato al topic ${topic}: ${message}`);
        }
      });
    } else {
      console.error("❌ Client MQTT non connesso, impossibile pubblicare.");
    }
  };

  useEffect(() => {
    // Tentativo di importare il file di configurazione dinamicamente
    try {
      const config = require("../config.js"); // Usa require per caricare il file dinamicamente
      if (config.WEBGATEWAYBROKER_URL) {
        setBrokerUrl(config.WEBGATEWAYBROKER_URL); // Se l'URL esiste nel file, usalo
        console.log(
          "WEBGATEWAYBROKER_URL trovato nel file di configurazione:",
          config.WEBGATEWAYBROKER_URL
        );
      } else {
        console.log(
          "WEBGATEWAYBROKER_URL non trovato nel file di configurazione."
        );
      }
    } catch (error) {
      console.error("config.js non trovato o errore di importazione.");
    }
  }, []);

  useEffect(() => {
    const client = mqtt.connect(brokerUrl, {
      connectTimeout: 5000, // Timeout di connessione
    });

    setClient(client); // Imposta il client MQTT

    client.on("connect", () => {
      console.log(`✅ Connesso al broker MQTT via WebSockets`);
      setStatus("✅ Connesso al broker MQTT");

      // Sottoscrivi al topic
      client.subscribe(topic, (err) => {
        if (!err) {
          console.log(`📡 Sottoscritto a ${topic}`);
          setStatus(`📡 Sottoscritto a ${topic}`);
        } else {
          console.error("❌ Errore nella sottoscrizione:", err);
          setStatus("❌ Errore nella sottoscrizione");
        }
      });
    });

    client.on("message", (topic, payload) => {
      console.log(`📥 Messaggio ricevuto su ${topic}: ${payload.toString()}`);
      try {
        const parsedMessage = JSON.parse(payload.toString());
        setMessage(parsedMessage);
      } catch (error) {
        console.error("❌ Errore nel parsing del messaggio:", error);
        setMessage({ error: "Messaggio non valido (non JSON)" });
      }
    });

    client.on("error", (err) => {
      console.error("❌ Errore di connessione:", err);
      setStatus("❌ Errore di connessione");
    });

    client.on("close", () => {
      console.log("⚠️ Connessione chiusa");
      setStatus("⚠️ Connessione chiusa");
    });

    // Timeout di connessione
    client.on("connect", () => {
      setStatus("✅ Connesso al broker MQTT");
    });

    client.on("error", (err) => {
      console.error("❌ Errore di connessione:", err);
      setStatus("❌ Errore di connessione");
    });

    return () => {
      client.end();
      console.log("🔌 Disconnessione dal broker MQTT");
      setStatus("🔌 Disconnesso");
    };
  }, [brokerUrl, topic]); // Trigger ogni volta che brokerUrl o topic cambiano

  return { message, status, publish }; // Ritorna la funzione publish
};

export default useMqttClient;


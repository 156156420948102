import React, { useEffect, useState, useRef } from "react";
import { useGLTF } from "@react-three/drei";
import {
  silverMaterial,
  darkGrayMaterial,
  blackMaterial,
} from "../colors/colors";
import useMqttClient from "../hooks/useMqttClient"; // Importa il custom hook MQTT

export default function Stazione3(props) {
  const { nodes } = useGLTF("/3D/Stazione3.gltf");

  // Stati per le posizioni degli assi e lo stato della porta
  const [asse1PositionZ, setAsse1PositionZ] = useState(0.2);
  const [asse2PositionX, setAsse2PositionX] = useState(0.5);
  const [asse3PositionY, setAsse3PositionY] = useState(0.0);
  const [door, setDoor] = useState(false);

  // Riferimenti per le porte
  const porta1Ref = useRef();
  const porta2Ref = useRef();

  // Utilizza useMqttClient per ricevere dati dal topic MQTT
  const { message } = useMqttClient("stazione3/telemetry");

  // Effetto per aggiornare le posizioni degli assi e lo stato della porta
  useEffect(() => {
    if (message) {
      setAsse1PositionZ(message.asse1);
      setAsse2PositionX(message.asse2);
      setAsse3PositionY(message.asse3);
      setDoor(message.door);
    }
  }, [message]);

  // Effetto per animare le porte in base allo stato 'door'
  useEffect(() => {
    let animationFrameId;

    const animate = () => {
      if (porta1Ref.current && porta2Ref.current) {
        if (door) {
          // Ruota la Porta2 fino a 90° (Math.PI / 2)
          if (porta2Ref.current.rotation.y < Math.PI / 2) {
            porta2Ref.current.rotation.y += 0.05;
          }
          // Ruota la Porta1 in senso opposto fino a -90° (-Math.PI / 2)
          if (porta1Ref.current.rotation.y > -Math.PI / 2) {
            porta1Ref.current.rotation.y -= 0.05;
          }
        } else {
          // Riporta le porte alla posizione iniziale (0°)
          if (porta1Ref.current.rotation.y < 0) {
            porta1Ref.current.rotation.y += 0.05;
          }
          if (porta2Ref.current.rotation.y > 0) {
            porta2Ref.current.rotation.y -= 0.05;
          }
        }
      }
      animationFrameId = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [door]);

  return (
    <group {...props} dispose={null}>
      <group position={[-0.009, -0.019, 0.009]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Struttura_1.geometry}
          material={blackMaterial}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Struttura_2.geometry}
          material={silverMaterial}
        />
      </group>
      <group position={[-0.069, 0.643, 0.069]} name="Porta1" ref={porta1Ref}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Griglia_porta.geometry}
          material={darkGrayMaterial}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Griglia_porta_1.geometry}
          material={blackMaterial}
        />
      </group>
      <group position={[1.44, 0.643, 0.069]} name="Porta2" ref={porta2Ref}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Griglia_porta001.geometry}
          material={darkGrayMaterial}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Griglia_porta001_1.geometry}
          material={blackMaterial}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Asse1.geometry}
        position={[0, 0, asse1PositionZ]}
        material={darkGrayMaterial}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Asse2.geometry}
          position={[asse2PositionX, 1.514, -1.411]}
          material={darkGrayMaterial}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Asse3.geometry}
            position={[-0.026, asse3PositionY, 0.097]}
            rotation={[1.572, 0, -3.141]}
            material={darkGrayMaterial}
          />
        </mesh>
      </mesh>
    </group>
  );
}

useGLTF.preload("/3D/Stazione3.gltf");

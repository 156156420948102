// src/App.js

import React from "react";
import Navbar from "../../../components/Navbar";
import Sidebar from "../../../components/Sidebar";
import useMqttClient from "../../../hooks/useMqttClient";

export default function App() {
  const { message, status } = useMqttClient("machine_6/#");

  return (
    <div className="section-page">
      <Navbar />
      <Sidebar />
      <div className="main-content">
        <div className="widget">
          <h1 style={{ color: "black" }}>MQTT Connection </h1>

          <div>
            <h2>Machine 6</h2>
            <p>
              <strong>Stato:</strong> {status}
            </p>

            <div>
              <strong>Messaggio ricevuto:</strong>
              <pre>{JSON.stringify(message, null, 2)}</pre>
            </div>

            {message && message.error && (
              <div style={{ color: "red" }}>
                <strong>Errore:</strong> {message.error}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import Navbar from "../../../components/Navbar";
import Sidebar from "../../../components/Sidebar";
import useMqttClient from "../../../hooks/useMqttClient";
import {
  FaPlay,
  FaStop,
  FaDeviantart,
  FaHandPaper,
  FaHandRock,
  FaHome,
  FaBatteryHalf,
} from "react-icons/fa";
import { Canvas } from "@react-three/fiber";
import Lighting from "../../GemelloDigitale/Lighting";
import { Stage, OrbitControls } from "@react-three/drei";
import RoboticArm2 from "../../../GLTFJSX/Fanuc_2";

export default function App() {
  const { message, publish } = useMqttClient("robotic_arm_2/#");

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");

  // Funzioni di azione per il robot
  const roboticArm = {
    start: async () => {
      // Invia un messaggio MQTT per avviare il robot
      publish("robotic_arm_2/start", "start");
    },
    reset: async () => {
      // Invia un messaggio MQTT per resettare il robot
      publish("robotic_arm_2/reset", "reset");
    },
    stop: async () => {
      // Invia un messaggio MQTT per fermare il robot
      publish("robotic_arm_2/stop", "stop");
    },
    openGripper: async () => {
      // Invia un messaggio MQTT per aprire la pinza
      publish("robotic_arm_2/openGripper", "open");
    },
    closeGripper: async () => {
      // Invia un messaggio MQTT per chiudere la pinza
      publish("robotic_arm_2/closeGripper", "close");
    },
    home: async () => {
      // Invia un messaggio MQTT per riportare il robot alla posizione iniziale
      publish("robotic_arm_2/home", "home");
    },
  };

  const handleAction = async (action) => {
    setLoading(true);
    setStatus("");
    try {
      await roboticArm[action]();
      setStatus(`${action} completed successfully!`);
    } catch (error) {
      setStatus(`${action} failed.`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="section-page">
      <Navbar />
      <Sidebar />
      <div className="main-content">
        <div className="widget-sx">
          <h1 style={{ color: "black" }}>Controls</h1>
          <button className="consumption-btn">
            <FaBatteryHalf className="icon" /> Consumption analysis
          </button>

          <div className="controls-container">
            <div className="control-card">
              <div className="icon-description">
                <FaPlay className="icon-widget" />
                <div className="description">
                  <h3>Start</h3>
                  <p>Initiate the robotic arm's movement.</p>
                </div>
              </div>
              <button
                onClick={() => handleAction("start")}
                className="action-btn"
              >
                Start
              </button>
              {loading && <div className="loading">Loading...</div>}
              {status && <div className="status-feedback">{status}</div>}
            </div>

            <div className="control-card">
              <div className="icon-description">
                <FaDeviantart className="icon-widget" />
                <div className="description">
                  <h3>Reset</h3>
                  <p>Reset the robotic arm to its initial position.</p>
                </div>
              </div>
              <button
                onClick={() => handleAction("reset")}
                className="action-btn"
              >
                Reset
              </button>
              {loading && <div className="loading">Loading...</div>}
              {status && <div className="status-feedback">{status}</div>}
            </div>

            <div className="control-card">
              <div className="icon-description">
                <FaStop className="icon-widget" />
                <div className="description">
                  <h3>Stop</h3>
                  <p>Stop the robotic arm's current movement.</p>
                </div>
              </div>
              <button
                onClick={() => handleAction("stop")}
                className="action-btn"
              >
                Stop
              </button>
              {loading && <div className="loading">Loading...</div>}
              {status && <div className="status-feedback">{status}</div>}
            </div>

            <div className="control-card">
              <div className="icon-description">
                <FaHandPaper className="icon-widget" />
                <div className="description">
                  <h3>Open Pinza</h3>
                  <p>Open the robotic arm's gripper.</p>
                </div>
              </div>
              <button
                onClick={() => handleAction("openGripper")}
                className="action-btn"
              >
                Open Pinza
              </button>
              {loading && <div className="loading">Loading...</div>}
              {status && <div className="status-feedback">{status}</div>}
            </div>

            <div className="control-card">
              <div className="icon-description">
                <FaHandRock className="icon-widget" />
                <div className="description">
                  <h3>Close Pinza</h3>
                  <p>Close the robotic arm's gripper.</p>
                </div>
              </div>
              <button
                onClick={() => handleAction("closeGripper")}
                className="action-btn"
              >
                Close Pinza
              </button>
              {loading && <div className="loading">Loading...</div>}
              {status && <div className="status-feedback">{status}</div>}
            </div>

            <div className="control-card">
              <div className="icon-description">
                <FaHome className="icon-widget" />
                <div className="description">
                  <h3>Home</h3>
                  <p>Return the robotic arm to its home position.</p>
                </div>
              </div>
              <button
                onClick={() => handleAction("home")}
                className="action-btn"
              >
                Home
              </button>
              {loading && <div className="loading">Loading...</div>}
              {status && <div className="status-feedback">{status}</div>}
            </div>
          </div>
        </div>

        <div className="widget-3d">
          <h1 style={{ color: "black" }}>3D Viewer</h1>
          <Canvas
            className="fullscreen-canvas"
            shadows
            camera={{ position: [0, 5, -10], fov: 60 }}
          >
            <color attach="background" />
            <Lighting />
            <Stage
              intensity={1.2}
              environment="warehouse"
              shadows={{ type: "soft" }}
              adjustCamera={0.5}
            />

            <RoboticArm2 position={[0, 0, 0]} rotation={[0, Math.PI, 0]} />

            <OrbitControls
              enableZoom={false}
              enableRotate={false}
              enablePan={false}
              makeDefault
              enabled={false}
            />
          </Canvas>
        </div>
      </div>
    </div>
  );
}
